function initMenuToggle() {
  const header = document.querySelector(".header");
  const nav = document.querySelector(".header__nav");
  const menuToggle = document.querySelector(".header__burger");

  // Exit if nav elements not found
  if (!nav || !menuToggle) return;

  // Add the click listener that toggles active classes
  menuToggle.addEventListener("click", function() {
    header.classList.toggle("header--active");
    nav.classList.toggle("header__nav--active");
    menuToggle.classList.toggle("header__burger--active");
  });

  // Remove active classes on window resize
  window.addEventListener("resize", function() {
    header.classList.remove("header--active");
    nav.classList.remove("header__nav--active");
    menuToggle.classList.remove("header__burger--active");
  });
}

initMenuToggle();
