/**
 * Deal with setting the new page
 */
function updateAbout(newAbout) {
  updatePagination(newAbout);
  updateImages(newAbout);
  updateTextAndVideo(newAbout);
}

/**
 * Update styles for pagination
 */
function updatePagination(newAbout) {
  for (let page of document.querySelectorAll(".about-pagination")) {
    let dot = page.querySelector(".about-pagination-dot");

    dot.style.backgroundColor =
      Number(page.getAttribute("data-company")) === newAbout
        ? dot.getAttribute("data-color")
        : "#fff";
  }
}

/**
 * Update size of images when the new company is selected
 */
function updateImages(newAbout) {
  // Loop through each images
  for (let image of document.querySelectorAll(".about-image")) {
    // Find the spacer for the current image
    let spacer = image.querySelector(".about-image-spacer");

    // If the number for this image matches the newAbout number then make it
    // bigger by adding/removing the appropriate classes
    if (Number(image.getAttribute("data-company")) === newAbout) {
      spacer.classList.remove("pt-5/4");
      spacer.classList.add("pt-3/2");
      continue;
    }

    setTimeout(function() {
      spacer.classList.add("pt-5/4");
      spacer.classList.remove("pt-3/2");
    }, 200);
  }
}

/**
 * Update the text and video
 */
function updateTextAndVideo(newAbout) {
  for (let textAndVideo of document.querySelectorAll(".about-text-video")) {
    if (Number(textAndVideo.getAttribute("data-company")) === newAbout) {
      textAndVideo.classList.remove("hidden");
      continue;
    }

    textAndVideo.classList.add("hidden");
  }
}

/**
 * Inititate and trigger click event listeners
 */
function initAbout() {
  let aboutTriggers = document.querySelectorAll(".about-trigger");

  if (!aboutTriggers) return;

  for (let trigger of aboutTriggers) {
    trigger.addEventListener("click", () =>
      updateAbout(Number(trigger.getAttribute("data-company")))
    );
  }
}

initAbout();
