function initImageGallery() {
  // Collect all image galleries
  let imageGalleries = document.querySelectorAll(".image-gallery");

  if (!imageGalleries) return;

  // Loop through each image gallery
  for (let imageGallery of imageGalleries) {
    let currentSlide = 0;
    let slideCount = imageGallery.querySelectorAll(".image-gallery-image")
      .length;
    let allPagination = imageGallery.querySelectorAll(
      ".image-gallery-pagination"
    );

    // Moves the new slide to the correct position, updates the pagination
    function updateSlides() {
      let newMarginLeft = "-" + currentSlide * 100 + "%";

      // Update the left margin on the first slide in order to show the selected slide
      imageGallery.querySelector(
        ".image-gallery-image"
      ).style.marginLeft = newMarginLeft;

      // Loop through and apply correct classes to pagination
      for (let pagination of allPagination) {
        pagination.classList[
          Number(pagination.getAttribute("data-gallery")) === currentSlide
            ? "add"
            : "remove"
        ]("bg-sss-blue");
      }
    }

    // Add functionality to pagination
    for (let pagination of allPagination) {
      pagination.addEventListener("click", () => {
        currentSlide = Number(pagination.getAttribute("data-gallery"));
        updateSlides();
      });
    }

    // Add functionality to next button
    imageGallery.querySelectorAll(".image-gallery-next").forEach(el =>
      el.addEventListener("click", () => {
        currentSlide = currentSlide + 1 === slideCount ? 0 : currentSlide + 1;
        updateSlides();
      })
    );

    // Add functionality to prev button
    imageGallery.querySelectorAll(".image-gallery-prev").forEach(el =>
      el.addEventListener("click", () => {
        currentSlide = currentSlide === 0 ? slideCount - 1 : currentSlide - 1;
        updateSlides();
      })
    );
  }
}

initImageGallery();
